import React from 'react';

import Layout from '../components/Layout';

export default function NotSupportedPage() {
    return (
        <Layout hideInformation={true}>
            <div
                style={{
                    padding: 120,
                    flex: 1,
                    textAlign: 'center',
                    height: '100%',
                }}
            >
                <h1>Browser Unsupported!</h1>
                <p>Internet Explorer is no longer supported.</p>
                <p>Please use Microsoft Edge, Google Chrome or Safari.</p>
                <img
                    src="/images/notfound.png"
                    alt=""
                    style={{ maxHeight: 450 }}
                />
            </div>
        </Layout>
    );
}
